import React from 'react';

// Components
import KeyValue from '../KeyValue';

// Style
import injectSheet from 'react-jss';
const styles = theme => {
  return {
    link: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
};

interface Props {
  classes: any;
  testLocation: any;
}

class TestLocationCard extends React.PureComponent<Props> {
  render() {
    const { classes, testLocation } = this.props;

    return (
      <KeyValue
        name="Test Center Selected: "
        ellipsisValue
        value={
          <a title={testLocation.name} href={`/location/${testLocation.slug}`} target="_blank">
            {testLocation.name}
          </a>
        }
      />
    );
  }
}

export default injectSheet(styles)(TestLocationCard);
