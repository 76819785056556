import React from 'react';
import classNames from 'classnames';
import injectSheet from 'react-jss';

interface Props {
  children: React.ReactNode;
  className?: string;
  classes: any;
}

function Modal({ className, children, classes }: Props) {
  return (
    <div className={classNames(classes.root, className)}>
      <h3>{children}</h3>
    </div>
  );
}

const styles = theme => {
  return {
    root: {
      flex: 0,
      padding: theme.spacing.unit * 2,
      paddingBottom: 0,
      paddingTop: 0,
    },
  };
};

export default injectSheet(styles)(Modal);
