import React from 'react';
import classnames from 'classnames';
import injectSheet from 'react-jss';

// Components
import Navigation from '../Navigation';
import Footer from '../Footer';

interface Props {
  classes: any;
  children: React.ReactNode;
  centered?: boolean;
  hideLinks?: boolean;
  belowFoldContent?: () => void;
}

function Page({ classes, hideLinks = false, children, centered, belowFoldContent }: Props) {
  return (
    <div className={classes.root}>
      <Navigation hideLinks={hideLinks} />
      <main className={classnames(classes.children, { [classes.childrenCentered]: centered })}>
        <div className={classnames(classes.content, { [classes.contentCentered]: centered })}>
          {children}
        </div>
      </main>
      <div className={classes.footerSpace} />
      {belowFoldContent && belowFoldContent()}
      <Footer />
    </div>
  );
}

const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    children: {
      margin: 'auto',
      display: 'flex',
      minHeight: '80vh',
      maxWidth: theme.widths.page,
      width: '100%',
      marginTop: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 2,
    },
    childrenCentered: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: 200,
      width: '100%',
    },
    content: {
      width: '100%',
    },
    contentCentered: {
      '& h2': {
        display: 'inline-block',
        margin: 'auto',
        marginBottom: theme.spacing.unit * 3,
        borderBottom: `3px solid ${theme.palette.primary.contrastText}`,
      },
    },
    footerSpace: {
      padding: theme.spacing.unit * 2,
    },
  };
};

export default injectSheet(styles)(Page);
