import React from 'react';
import PropTypes from 'prop-types';

// Style
import injectSheet from 'react-jss';
import classnames from 'classnames';

const styles = theme => {
  return {
    button: {
      border: 'none',
      borderRadius: 2,
      fontSize: 16,
      fontWeight: 600,
      margin: theme.spacing.unit / 2,
      padding: theme.spacing.unit * 1,
      color: theme.palette.common.white,
      boxShadow: `0px 0px 4px #000`,
      '&:disabled': {
        boxShadow: 'none',
      },
    },
    noneButtonSize: {
      minWidth: 0,
    },
    smallButtonSize: {
      minWidth: 100,
    },
    normalButtonSize: {
      minWidth: 250,
    },
    white: {
      background: theme.palette.common.white,
      color: theme.palette.primary.contrastText,
      transition: [theme.transition.norm, 'background'],
      '&:not([disabled]):hover': {
        transition: [theme.transition.norm, 'background'],
        background: theme.palette.common.gray,
        cursor: 'pointer',
      },
      '&:active': {
        background: theme.palette.common.divider + ' !important',
      },
      '&:focus': {
        background: theme.palette.common.gray + ' !important',
      },
    },
    moonBlue: {
      background: theme.palette.primary.main,
      '&:not([disabled]):hover': {
        transition: [theme.transition.norm, 'background'],
        background: theme.palette.primary.dark,
        cursor: 'pointer',
      },
      '&:disabled': {
        background: theme.palette.primary.disabled,
      },
      '&:active': {
        background: theme.palette.primary.main + ' !important',
      },
      '&:focus': {
        background: theme.palette.primary.light + ' !important',
      },
    },
    hyperlinkMoonBlue: {
      color: theme.palette.primary.text,
      fontSize: 14,
      boxShadow: `none`,
      background: 'none',
      '&:not([disabled]):hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    hyperlinkWhite: {
      color: theme.palette.common.white,
      fontSize: 14,
      boxShadow: `none`,
      background: 'none',
      '&:not([disabled]):hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  };
};

class Button extends React.PureComponent {
  render() {
    const {
      classes,
      children,
      className,
      color,
      onClick = () => {},
      disabled = false,
      size = 'normal',
    } = this.props;

    return (
      <button
        className={classnames(
          classes.button,
          classes[color],
          className,
          classes[size + 'ButtonSize'],
        )}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.string,
};

export default injectSheet(styles)(Button);
