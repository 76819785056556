import React from 'react';
import classNames from 'classnames';
import injectSheet from 'react-jss';

interface Props {
  children: React.ReactNode;
  className?: string;
  classes: any;
}

function ModalActions({ className, children, classes }: Props) {
  return <div className={classNames(classes.root, className)}>{children}</div>;
}

const styles = theme => {
  return {
    root: {
      padding: theme.spacing.unit * 2,
      flex: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        marginLeft: theme.spacing.unit,
      },
    },
  };
};

export default injectSheet(styles)(ModalActions);
