import React from 'react';
import PropTypes from 'prop-types';

// Style
import injectSheet from 'react-jss';

const styles = theme => {
  return {
    header: {
      fontSize: 16,
      fontWeight: 600,
      paddingLeft: theme.spacing.unit / 4,
    },
  };
};

class InputHeader extends React.PureComponent {
  render() {
    const { classes, forId, children } = this.props;

    return (
      <label htmlFor={forId} className={classes.header}>
        {children}
      </label>
    );
  }
}

InputHeader.propTypes = {
  forId: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(InputHeader);
