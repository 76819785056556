import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import injectSheet from 'react-jss';
import usePrevious from '../../_helpers/usePrevious';
import root from 'window-or-global';

// Components
import Portal from '../Portal';

interface Props {
  children: React.ReactNode;
  className?: string;
  open: Boolean;
  classes: any;
}

function Modal({ className, children, open, classes }: Props) {
  const [onMount, setOnMount] = useState(false);
  const [onDismount, setOnDismount] = useState(false);
  const prevOpen = usePrevious(open);

  useEffect(() => {
    if (open) {
      root.document.body.style.overflow = 'hidden';
      setOnDismount(false);
      setTimeout(() => {
        setOnMount(true);
      }, 0);
    }

    if (!open) {
      root.document.body.style.overflow = '';
    }

    if (!open && prevOpen) {
      setOnDismount(true);
      setTimeout(() => {
        setOnMount(false);
      }, 300);
    }
  }, [open, prevOpen]);

  return (
    <Portal open={open || onMount}>
      <div
        className={classNames(classes.overlay, {
          [classes.overlayOnMount]: onMount && !onDismount,
        })}
      >
        <div
          className={classNames(classes.modal, className, {
            [classes.onMount]: onMount && !onDismount,
            // [classes.onDismount]: onDismount,
          })}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
}

const styles = theme => {
  return {
    modal: {
      backgroundColor: '#fff',
      borderRadius: 5,
      maxWidth: 450,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      transition: 'all .4s',
      opacity: 0,
      marginTop: 0,
      marginBottom: theme.spacing.unit * 2,
    },
    onMount: {
      opacity: 1,
      marginTop: theme.spacing.unit * 8,
    },
    overlay: {
      position: 'fixed',
      background: 'rgba(10, 10, 10, 0.0)',
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      top: theme.spacing.unit * -5,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 10,
      transition: 'all .4s',
      display: 'inline-grid',
      alignItems: 'center',
      overflowY: 'scroll',
      overflowX: 'hidden',
      [theme.media.xs]: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
      },
    },
    overlayOnMount: {
      background: 'rgba(10, 10, 10, 0.3)',
    },
  };
};

export default injectSheet(styles)(Modal);
