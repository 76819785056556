import React, { useEffect, useState } from 'react';
import createSuggestLocation from '@testnotify/core/api/create-location-suggestion';
import StatesModel from '../../../_helpers/states.model';
import emailRegex from '../../../_helpers/emailRegex';
import useSnackbar from '../../Snackbar/useSnackbar';
import theme from '../../../styles/theme';

// Components
import Modal, { ModalHeader, ModalContent, ModalActions } from '../../Modal';
import Button from '../../Button';
import Input from '../../Input';
import Select from 'react-select';
import NoSSR from 'react-no-ssr';

interface Props {
  open: boolean;
  toggleModal: () => void;
}

function SuggestLocation({ open, toggleModal }: Props) {
  const { addToSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    locationName: '',
    locationState: '',
    email: '',
    message: '',
  });
  const { locationName, locationState, email, message } = state;
  const invalidEmail = !!email ? !emailRegex.test(email) : false;
  const disabled = !locationName || !locationState || !email || invalidEmail;
  const invalidEmailMessage = 'Sorry, this email is not valid.';

  useEffect(() => {
    if (!open) {
      setState({
        locationName: '',
        locationState: '',
        email: '',
        message: '',
      });
    }
  }, [open]);

  const handleChange = (value, key) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleSend = async () => {
    setIsLoading(true);
    createSuggestLocation({ locationName, locationState, email, message })
      .then(data => {
        addToSnackbar('Your submission has gone through.', {
          variant: 'success',
        });
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        addToSnackbar('There was an error on our end.', {
          variant: 'error',
        });
      });
  };

  return (
    // @ts-ignore
    <Modal open={open}>
      <ModalHeader>Suggest a Testing Center</ModalHeader>
      <ModalContent>
        <p>Suggest us a test center and we will contact you when we have added it.</p>
        <Input
          forId="suggest-test-center"
          type="text"
          header="Test Center Name:"
          disabled={isLoading}
          onChange={e => handleChange(e.target.value, 'locationName')}
        />
        <NoSSR>
          <Input header="State:" forId="select-state">
            <Select
              id="select-state"
              options={StatesModel}
              placeholder="Select State..."
              disabled={isLoading}
              onChange={selectedOption => handleChange(selectedOption.value, 'locationState')}
              theme={libTheme => ({
                ...libTheme,
                borderRadius: 0,
                colors: {
                  ...libTheme.colors,
                  primary: theme.palette.primary.light,
                },
              })}
            />
          </Input>
        </NoSSR>
        <Input
          forId="your-email"
          type="email"
          header="Your Email:"
          onChange={e => handleChange(e.target.value, 'email')}
          error={invalidEmail}
          errorMessage={invalidEmail ? invalidEmailMessage : ''}
          disabled={isLoading}
        />
        <Input
          forId="your-comments"
          type="textarea"
          header="Website Link / Address: (optional)"
          onChange={e => handleChange(e.target.value, 'message')}
          disabled={isLoading}
        />
      </ModalContent>
      <ModalActions>
        <Button color="white" onClick={toggleModal} size="small">
          Close
        </Button>
        <Button color="moonBlue" onClick={handleSend} disabled={disabled || isLoading} size="small">
          Send
        </Button>
      </ModalActions>
    </Modal>
  );
}

// @ts-ignore
export default SuggestLocation;
