import React from 'react';
import injectSheet from 'react-jss';

// Components
import Icon from '../../Icon';

const styles = theme => {
  return {
    valueContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    key: {},
    value: {},
    iconContainer: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      display: 'flex',
      '& > span': {
        fontSize: 20,
      },
    },
  };
};

interface Props {
  classes: any;
  form: {
    startDate?: any;
    endDate?: any;
  };
}

function DatesSelected({ classes, form }: Props) {
  return (
    <div>
      <div className={classes.key}>Dates Selected:</div>
      <div className={classes.valueContainer}>
        <span className={classes.value}>{form.startDate.format('ddd, MMM D, YYYY')} </span>
        <span className={classes.iconContainer}>
          <Icon>arrow_forward</Icon>
        </span>
        <span className={classes.value}>{form.endDate.format('ddd, MMM D, YYYY')}</span>
      </div>
    </div>
  );
}

export default injectSheet(styles)(DatesSelected);
