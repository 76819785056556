import React, { useEffect, useState } from 'react';
import createContactMessage from '@testnotify/core/api/create-contact-message';
import emailRegex from '../../../_helpers/emailRegex';
import useSnackbar from '../../Snackbar/useSnackbar';

// Components
import Modal, { ModalHeader, ModalContent, ModalActions } from '../../Modal';
import Button from '../../Button';
import Input from '../../Input';

interface Props {
  open: boolean;
  toggleModal: () => void;
}

function ContactUs({ open, toggleModal }: Props) {
  const { addToSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({ name: '', message: '', email: '' });
  const { email, message, name } = state;
  const invalidEmail = !!email ? !emailRegex.test(email) : false;
  const disabled = !name || !message || !email || invalidEmail;
  const invalidEmailMessage = 'Sorry, this email is not valid.';

  useEffect(() => {
    if (!open) {
      setState({ name: '', message: '', email: '' });
    }
  }, [open]);

  const handleChange = (value, key) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleSend = () => {
    const { name, email, message } = state;
    setIsLoading(true);
    createContactMessage({ name, email, message })
      .then(data => {
        setIsLoading(false);
        addToSnackbar('Your message has been sent to us.', {
          variant: 'success',
        });
        toggleModal();
      })
      .catch(error => {
        setIsLoading(false);
        addToSnackbar('There was an error on our end.', {
          variant: 'error',
        });
      });
  };

  return (
    // @ts-ignore
    <Modal open={open}>
      <ModalHeader>Contact Us</ModalHeader>
      <ModalContent>
        <p>How can we help?</p>
        <Input
          forId="your-name"
          type="text"
          header="Your Name:"
          onChange={e => handleChange(e.target.value, 'name')}
          disabled={isLoading}
        />
        <Input
          forId="your-email"
          type="email"
          header="Your Email:"
          onChange={e => handleChange(e.target.value, 'email')}
          error={invalidEmail}
          errorMessage={invalidEmail ? invalidEmailMessage : ''}
          disabled={isLoading}
        />
        <Input
          forId="your-comments"
          type="textarea"
          header="Feedback:"
          onChange={e => handleChange(e.target.value, 'message')}
          disabled={isLoading}
        />
      </ModalContent>
      <ModalActions>
        <Button color="white" onClick={toggleModal} size="small">
          Close
        </Button>
        <Button color="moonBlue" onClick={handleSend} disabled={disabled || isLoading} size="small">
          Send
        </Button>
      </ModalActions>
    </Modal>
  );
}

// @ts-ignore
export default ContactUs;
