import React from 'react';
import PropTypes from 'prop-types';

// Components
import ContactUs from '../modals/ContactUs';
import SuggestLocation from '../modals/SuggestLocation';
import ConfirmIdentifierLogPayment from '../modals/ConfirmIdentifierLogPayment';

class ModalWrapper extends React.PureComponent {
  state = {
    open: false,
  };

  modals = {
    ContactUs: ContactUs,
    SuggestLocation: SuggestLocation,
    ConfirmIdentifierLogPayment: ConfirmIdentifierLogPayment,
  };

  toggleModal = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      this.toggleModal();
    }
  };

  render() {
    const { className, modalProps, children, type, style } = this.props;
    const Modal = this.modals[type];
    if (!Modal) {
      console.error('error: modal is undefined');
      return null;
    }

    return (
      <>
        <div
          onClick={this.toggleModal}
          className={className}
          style={style}
          tabIndex="0"
          onKeyDown={this.handleKeyDown}
        >
          {children}
        </div>
        <Modal open={this.state.open} toggleModal={this.toggleModal} {...modalProps} />
      </>
    );
  }
}

ModalWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  modalProps: PropTypes.object,
};

export default ModalWrapper;
