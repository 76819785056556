import { useContext } from 'react';
import SnackbarContext from './SnackbarContext';

export default function useSnackbar() {
  const snackbarContext = useContext(SnackbarContext);

  return {
    ...snackbarContext,
  };
}
