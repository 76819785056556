import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

export const styles = theme => ({
  root: {
    userSelect: 'none',
    fontSize: 24,
    width: '1em',
    height: '1em',
    overflow: 'hidden',
    flexShrink: 0,
  },
});

interface Props {
  children: any;
  classes: any;
  style?: any;
  className?: string;
}

function Icon(props: Props) {
  const { children, classes, ...other } = props;

  return (
    <span className={classnames('material-icons', classes.root)} aria-hidden="true" {...other}>
      {children}
    </span>
  );
}

export default injectSheet(styles)(Icon);
