import React from 'react';
import injectSheet from 'react-jss';

interface Props {
  classes: any;
  title: string;
  subheader?: string;
}

function SectionTitle({ classes, title, subheader }: Props) {
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <h1>{title}</h1>
      </div>
      {subheader && <div className={classes.subheader}>{subheader}</div>}
    </div>
  );
}

const styles = theme => {
  return {
    root: {
      width: '100%',
      marginBottom: theme.spacing.unit * 3,
    },
    title: {
      borderBottom: `3px solid ${theme.palette.primary.contrastText}`,
      '& h1': {
        fontSize: 28,
        marginBottom: 0,
      },
      [theme.media.sm]: {
        '& h1': {
          fontSize: 20,
          textAlign: 'center',
          marginBottom: theme.spacing.unit / 2,
        },
      },
    },
    subheader: {
      padding: [theme.spacing.unit, theme.spacing.unit / 4],
    },
  };
};

export default injectSheet(styles)(SectionTitle);
