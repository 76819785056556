import root from 'window-or-global';
import * as Store from './types';

// Action Types
export const SET_LOGIN = 'SET_LOGIN';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_ACCOUNT_LOCAL_STORAGE = 'SET_ACCOUNT_LOCAL_STORAGE';

// Actions
interface SetLogin {
  authToken?: string;
  email?: string;
}

export const setLogin = ({ authToken, email }: SetLogin) => {
  return {
    type: SET_LOGIN,
    payload: {
      authToken,
      email,
    },
  };
};

interface SetProfile {
  testCenterSelected?: string;
  identifierSelected?: string;
}

export const setProfile = ({ testCenterSelected, identifierSelected }: SetProfile) => {
  return {
    type: SET_PROFILE,
    payload: {
      testCenterSelected,
      identifierSelected,
    },
  };
};

export const setAccountLocalStorage = () => {
  return {
    type: SET_ACCOUNT_LOCAL_STORAGE,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [SET_LOGIN]: (state, action) => {
    root.localStorage.setItem('tn-auth-token', action.payload.authToken);
    root.localStorage.setItem('tn-email', action.payload.email);

    return {
      ...state,
      authToken: action.payload.authToken,
      email: action.payload.email,
    };
  },
  [SET_PROFILE]: (state, action) => {
    root.localStorage.setItem('tn-test-center-selected', action.payload.testCenterSelected);
    root.localStorage.setItem('tn-identifier-selected', action.payload.identifierSelected);

    return {
      ...state,
      testCenterSelected: action.payload.testCenterSelected,
      identifierSelected: action.payload.identifierSelected,
    };
  },
  [SET_ACCOUNT_LOCAL_STORAGE]: state => {
    if (!root) return { ...state };
    const authToken = root.localStorage.getItem('tn-auth-token');
    const email = root.localStorage.getItem('tn-email');
    const testCenterSelected = root.localStorage.getItem('tn-test-center-selected');
    const identifierSelected = root.localStorage.getItem('tn-identifier-selected');

    return {
      ...state,
      authToken,
      email,
      testCenterSelected: JSON.parse(testCenterSelected),
      identifierSelected: JSON.parse(identifierSelected),
    };
  },
};

// Reducer
export const initialState = {
  authToken: null,
  email: null,
  testCenterSelected: null,
  identifierSelected: null,
};

export default function authReducer(state = initialState, action = {} as Store.Action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
