import React from 'react';
import classNames from 'classnames';
import injectSheet from 'react-jss';

interface Props {
  children: React.ReactNode;
  className?: string;
  classes: any;
}

function Modal({ className, children, classes }: Props) {
  return <div className={classNames(classes.root, className)}>{children}</div>;
}

const styles = theme => {
  return {
    root: {
      flex: '1 1 auto',
      padding: theme.spacing.unit * 2,
      paddingTop: 0,
    },
  };
};

export default injectSheet(styles)(Modal);
