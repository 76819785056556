import React, { useState, useEffect } from 'react';
import theme from '../../styles/theme';
import { useSelector } from 'react-redux';
import root from 'window-or-global';
import injectSheet from 'react-jss';
import classnames from 'classnames';

// Components
import Logo from '../Logo';
import LogoImage from '../LogoImage';
import Icon from '../Icon';
import ProfileDropdown from '../ProfileDropdown';
import Link from 'next/link';

interface Props {
  classes: any;
  hideLinks?: boolean;
}

function Navigation({ hideLinks, classes }: Props) {
  const { authToken, testCenterSelected, identifierSelected } = useSelector(state => state.auth);
  const loggedIn = !!authToken;
  const notificationsHasBeenSetup = !!testCenterSelected && !!identifierSelected;

  const [mobileShowMenu, setMobileShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const handleClick = () => {
    setMobileShowMenu(!mobileShowMenu);
  };

  useEffect(() => {
    if (root && root.innerWidth < theme.widths.mobileNav) {
      setIsMobile(true);
    }

    setHasMounted(true);
  }, []);

  return (
    <nav className={classes.root}>
      <div className={classes.navigationContainer}>
        <div className={classes.navigation}>
          <div className={classes.logo}>
            <LogoImage />
            <Logo />
          </div>
          {!hideLinks && hasMounted && (
            <>
              <div className={classes.mobileMenuContainer}>
                <div onClick={handleClick} className={classes.mobileMenu}>
                  <Icon>menu</Icon>
                </div>
              </div>

              <div
                className={classnames(classes.routes, classes.mobileSlider)}
                style={{ top: mobileShowMenu ? 0 : undefined }}
                aria-hidden={isMobile}
              >
                <Link href="/">
                  <div className={classes.route}>Home</div>
                </Link>

                {!notificationsHasBeenSetup && (
                  <Link href="/setup-notifications">
                    <div className={classes.route}>Setup Notifications</div>
                  </Link>
                )}

                <Link href="/identifier-backlog">
                  <div className={classes.route}>Identifier Backlog</div>
                </Link>

                {!loggedIn && (
                  <>
                    <Link href="/sign-in">
                      <div className={classes.route}>Sign in</div>
                    </Link>
                    <Link href="/sign-up">
                      <div className={classes.route}>Sign up</div>
                    </Link>
                  </>
                )}
                <ProfileDropdown className={classes.route} />

                {/* {loggedIn && <Logout className={classes.route} />} */}
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

const styles = theme => {
  return {
    root: {
      paddingBottom: theme.heights.navbar,
    },

    logo: {
      display: 'flex',
    },

    navigationContainer: {
      display: 'flex',
      height: theme.heights.navbar,
      width: '100%',
      zIndex: 10,
      backgroundColor: 'rgba(255, 255, 255, .9) !important',
      padding: [theme.spacing.unit, theme.spacing.unit * 2],
      paddingLeft: theme.spacing.unit * 4,
      position: 'fixed',
      [theme.media.md]: {
        width: '100vw',
      },
    },

    navigation: {
      display: 'flex',
      alignItems: 'center',
      margin: 'auto',
      width: '100%',
      maxWidth: theme.widths.page,
    },
    mobileSlider: {
      [theme.media.mobileNav]: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.common.white,
        width: '75%',
        left: 0,
        transition: 'top .4s',
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        border: '1px solid #d3d3d3',
        top: '-300px',
      },
    },
    mobileMenuContainer: {
      display: 'none',
      [theme.media.mobileNav]: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'flex-end',
      },
    },

    mobileMenu: {
      cursor: 'pointer',
      zIndex: 1,
      background: theme.palette.common.white,
      paddingBottom: 0,
    },

    routes: {
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'flex-end',
    },

    route: {
      cursor: 'pointer',
      marginLeft: theme.spacing.unit,
      letterSpacing: 1,
      color: theme.palette.primary.contrastText + ' !important',
      transition: [theme.transition.norm, 'background'],
      padding: theme.spacing.unit,
      '&:hover': {
        background: theme.palette.common.gray,
      },
    },
  };
};

export default injectSheet(styles)(Navigation);
