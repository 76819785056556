import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classnames from 'classnames';
import baseInputStyles from './baseInputStyles';

// Components
import InputHeader from './InputHeader';
import ErrorMessage from '../ErrorMessage';

class Input extends React.PureComponent {
  state = {
    hasBlurred: false,
  };

  componentDidMount() {
    if (this.input) {
      this.input.focus();
    }
  }

  handleBlur = () => {
    this.setState({ hasBlurred: true });
  };

  renderInput = () => {
    const {
      focus,
      type = 'text',
      className,
      errorMessage,
      header,
      classes,
      error,
      label,
      forId,
      ...inputProps
    } = this.props;

    const componentProps = {
      ...inputProps,
      type,
      ref: focus
        ? input => {
            this.input = input;
          }
        : false,
      className: classnames(classes.input, {
        [classes.inputError]: this.state.hasBlurred && error,
        [className]: !!className,
        [classes[type]]: !!classes[type],
      }),
      id: forId,
      onBlur: this.handleBlur,
      'aria-label': inputProps.placeholder,
    };

    return type === 'textarea' ? <textarea {...componentProps} /> : <input {...componentProps} />;
  };

  render() {
    const { forId, classes, children, header, errorMessage } = this.props;
    const childrenElem = children ? children : this.renderInput();

    return (
      <div className={classes.root}>
        {header && <InputHeader forId={forId}>{header}</InputHeader>}
        {childrenElem}
        {this.state.hasBlurred && errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
    );
  }
}

Input.propTypes = {
  forId: PropTypes.string,
  focus: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const styles = theme => {
  return {
    root: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit * 2,
      fontSize: 16,
      [theme.media.md]: {
        fontSize: 14,
      },
    },
    input: {
      width: '100%',
    },
    inputError: {
      border: `2px dashed ${theme.palette.error.text} !important`,
    },

    // Class Types
    text: {
      ...baseInputStyles(theme),
    },
    password: {
      ...baseInputStyles(theme),
    },
    phone: {
      ...baseInputStyles(theme),
    },
    email: {
      ...baseInputStyles(theme),
    },
    checkbox: {},
    textarea: {
      ...baseInputStyles(theme),
      minHeight: 90,
    },
  };
};

export default injectSheet(styles)(Input);
