import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { setLogin, setProfile } from '../../store/auth';

interface Props {
  className?: string;
}

function Logout({ className }: Props) {
  const dispatch = useDispatch();
  const router = useRouter();

  const logout = useCallback(() => {
    dispatch(setLogin({ authToken: null, email: null }));
    dispatch(setProfile({ testCenterSelected: null, identifierSelected: null }));
    router.push(`/sign-in`);
  }, [router, dispatch]);

  return (
    <div className={className} onClick={logout}>
      Logout
    </div>
  );
}

export default Logout;
