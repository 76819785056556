import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import theme from '../../styles/theme';
import packageJson from '../../../package.json';
import injectSheet from 'react-jss';

// Components
import LogoImage from '../LogoImage';
import Logo from '../Logo';
import ModalWrapper from '../ModalWrapper';
import Link from 'next/link';

interface Props {
  classes: any;
}

const ROUTE_A_TAG_STYLE = {
  marginBottom: theme.spacing.unit,
  marginLeft: -theme.spacing.unit / 2,
  padding: theme.spacing.unit / 2,
  color: theme.palette.defaultText,
};

const POLICY_A_TAG_STYLE = {
  marginRight: theme.spacing.unit * 2,
  marginLeft: -theme.spacing.unit * 1.9,
  padding: theme.spacing.unit * 1.9,
  color: theme.palette.defaultText,
  fontSize: 14,
};

function Footer({ classes }) {
  const [hasMounted, setHasMounted] = useState(false);
  const currentTime = new Date();
  const currentYear = currentTime.getFullYear();
  const { authToken, testCenterSelected, identifierSelected } = useSelector(state => state.auth);
  const loggedIn = !!authToken;
  const notificationsHasBeenSetup = testCenterSelected && identifierSelected;

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <footer className={classes.root}>
      <div className={classes.container}>
        <div className={classes.brand}>
          <LogoImage />
          <Logo />
        </div>
        <div className={classes.footerContent}>
          <div className={classes.routesContainer}>
            <div className={classes.routes}>
              {hasMounted && (
                <>
                  {!notificationsHasBeenSetup && (
                    <>
                      <Link href="/setup-notifications">
                        <a href="/setup-notifications" style={ROUTE_A_TAG_STYLE}>
                          Setup Notifications
                        </a>
                      </Link>
                      <br />{' '}
                    </>
                  )}
                  <Link href="/identifier-backlog">
                    <a href="/identifier-backlog" style={ROUTE_A_TAG_STYLE}>
                      Identifier Backlog
                    </a>
                  </Link>
                  <br />
                  <ModalWrapper
                    className={classes.aTagStyles}
                    style={ROUTE_A_TAG_STYLE}
                    type="SuggestLocation"
                  >
                    Suggest a Testing Center
                  </ModalWrapper>
                  <br />
                  <ModalWrapper
                    className={classes.aTagStyles}
                    style={ROUTE_A_TAG_STYLE}
                    type="ContactUs"
                  >
                    Contact Us
                  </ModalWrapper>
                  <br />
                  {!loggedIn && (
                    <>
                      <Link href="/sign-in">
                        <a href="/sign-in" style={ROUTE_A_TAG_STYLE}>
                          Sign In
                        </a>
                      </Link>
                      <span> / </span>
                      <Link href="/sign-up">
                        <a href="/sign-up" style={ROUTE_A_TAG_STYLE}>
                          Sign up
                        </a>
                      </Link>
                      <br />
                    </>
                  )}
                </>
              )}
            </div>
            <div className={classes.mobileColumn}>
              <div className={classes.rowTitle}>Download the App</div>
              <img
                className={classes.appleAppStore}
                src="https://imgur.com/x6d4lCL.png"
                alt="iOS download testnotify"
              />
              <img
                className={classes.googleStore}
                src="https://imgur.com/dRaeSWd.png"
                alt="android store download testnotify"
              />
            </div>
            <div className={classes.spacerColumn} />
          </div>

          <div>
            <div className={classes.legalRoutes}>
              <Link href="/privacy-policy" prefetch={false}>
                <a href="/privacy-policy" style={POLICY_A_TAG_STYLE}>
                  Privacy Policy
                </a>
              </Link>
              <br className={classes.showOnXS} />
              <Link href="/terms-of-service" prefetch={false}>
                <a href="/terms-of-service" style={POLICY_A_TAG_STYLE}>
                  Terms of Service
                </a>
              </Link>
              <br className={classes.showOnXS} />
              <Link href="/cookies" prefetch={false}>
                <a href="/cookies" style={POLICY_A_TAG_STYLE}>
                  Cookies Policy
                </a>
              </Link>
            </div>
            <br className={classes.showOnXS} />
            <div style={{ fontSize: 14 }}>
              Made with{' '}
              <svg
                className={classes.heart}
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 24 24"
              >
                <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
              </svg>{' '}
              in Detroit
            </div>
            <div className={classes.copyRight}>
              <div style={{ marginRight: 16 }}>TestNotify &copy; {currentYear}</div>
              <div className={classes.packageVersion}>v{packageJson.version}</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const styles = theme => {
  return {
    root: {
      color: theme.palette.primary.contrastText,
      background: theme.palette.common.gray,
    },
    container: {
      display: 'flex',
      margin: 'auto',
      maxWidth: theme.widths.page + 48, // 48 aligns with navbar
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit,
      paddingTop: theme.spacing.unit * 4,
      paddingBottom: theme.spacing.unit * 2,
      flexDirection: 'row',
      [theme.media.md]: {
        flexDirection: 'column',
      },
    },

    footerContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: theme.widths.content,
      margin: 'auto',
      width: '100%',

      [theme.media.md]: {
        maxWidth: 'initial',
      },
    },

    brand: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit * 3,
      display: 'flex',
    },

    routesContainer: {
      marginTop: theme.spacing.unit,
      display: 'flex',
      flexWrap: 'wrap',
    },

    routes: {
      width: '100%',
      maxWidth: 400,
      marginBottom: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 8,
      '& > a, & > div': {
        marginBottom: theme.spacing.unit * 2,
        display: 'inline-block',
        color: theme.palette.primary.contrastText,
      },
      '& > a:hover, & > div:hover': {
        textDecoration: 'underline',
      },
    },

    legalRoutes: {
      marginBottom: theme.spacing.unit * 1.5,
      '& > a, & > div': {
        marginRight: theme.spacing.unit * 1.5,
        fontSize: 14,
        color: theme.palette.primary.contrastText,
      },
      '& > a:hover, & > div:hover': {
        textDecoration: 'underline',
      },
    },

    copyRight: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing.unit * 1.5,
      marginBottom: theme.spacing.unit,
      fontSize: 14,
      [theme.media.sm]: {
        flexDirection: 'column',
      },
    },

    aTagStyles: {
      display: 'inline',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },

    heart: {
      fill: '#fa3f3e',
    },

    mobileColumn: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing.unit * 4,
    },
    rowTitle: {
      marginBottom: theme.spacing.unit,
      fontWeight: 600,
    },
    appleAppStore: {
      width: 150,
      marginBottom: theme.spacing.unit,
      cursor: 'pointer',
    },
    googleStore: {
      width: 150,
      cursor: 'pointer',
    },
    packageVersion: {
      fontSize: 9,
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 2,
      [theme.media.sm]: {},
    },
    showOnXS: {
      display: 'none',
      [theme.media.xs]: {
        display: 'block',
      },
    },
  };
};

export default injectSheet(styles)(Footer);
