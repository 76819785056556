import React from 'react';
import injectSheet from 'react-jss';
import classnames from 'classnames';

const styles = theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing.unit,
      [theme.media.xs]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: theme.spacing.unit * 3,
      },
    },
    key: {
      flex: '0 0 auto',
      maxWidth: 200,
      [theme.media.xs]: {
        textDecoration: 'underline',
      },
    },
    value: {
      [theme.media.xs]: {
        maxWidth: `calc(100vw - ${theme.spacing.unit * 8}px)`,
        alignSelf: 'flex-end',
      },
    },
    ellipsisValue: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    spacer: {
      flex: 1,
      borderTop: `1px dotted`,
      alignSelf: 'flex-end',
      paddingBottom: theme.spacing.unit,
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      [theme.media.xs]: {
        display: 'none',
      },
    },
  };
};

interface Props {
  name: string;
  value: any;
  classes: any;
  ellipsisValue?: boolean;
}

function KeyValue({ classes, name, ellipsisValue, value }: Props) {
  return (
    <div className={classes.root}>
      <div className={classes.key}>{name}</div>
      <div className={classes.spacer} />
      <div className={classnames(classes.value, { [classes.ellipsisValue]: ellipsisValue })}>
        {value}
      </div>
    </div>
  );
}

export default injectSheet(styles)(KeyValue);
