import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Style
import injectSheet from 'react-jss';
import classnames from 'classnames';
const styles = theme => {
  return {
    root: {
      fontSize: 24,
      '& a': {
        color: theme.palette.primary.main + ' !important',
      },
    },
    test: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    notify: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
  };
};

class Logo extends React.PureComponent {
  render() {
    const { classes, className } = this.props;
    return (
      <div className={classnames(classes.root, className)}>
        <Link href="/">
          <a href="/">
            <span className={classes.test + ' onLoadFont'}>Test</span>
            <span className={classes.notify + ' onLoadFont'}>Notify</span>
          </a>
        </Link>
      </div>
    );
  }
}

Logo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Logo);
