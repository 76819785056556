import React from 'react';
import { createPortal } from 'React-dom';
import root from 'window-or-global';

interface Props {
  children: React.ReactNode;
  open: Boolean;
}

function Portal({ children, open }: Props) {
  return root && root.document && open ? createPortal(children, root.document.body) : null;
}

export default Portal;
