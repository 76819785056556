export default theme => {
  const Theme = theme ? theme : { spacing: { unit: 8 } };

  return {
    minWidth: 340,
    height: 44,
    padding: [Theme.spacing.unit / 4, Theme.spacing.unit],
    border: '1px solid rgba(0,0,0,.2)',
    fontSize: 16,
    [theme.media.md]: {
      minWidth: 'auto',
      fontSize: 14,
    },
  };
};
