import React from 'react';

// Components
import injectSheet from 'react-jss';

const styles = theme => {
  return {
    purchaseCopyContainer: {
      marginTop: theme.spacing.unit * 2,
      maxWidth: 190,
      textAlign: 'right',

      '& span': {
        fontWeight: 600,
        width: 75,
        display: 'inline-block',
      },
    },
    totalCost: {
      borderTop: `1px solid`,
    },
  };
};

interface Props {
  classes: any;
  form: {
    startDate?: any;
    endDate?: any;
  };
}

function TotalCost({ classes, form }: Props) {
  const { startDate, endDate } = form;

  const totalDaysSelected =
    !!startDate && !!endDate && form.endDate.diff(form.startDate, 'days') + 1;

  return (
    <div className={classes.purchaseCopyContainer}>
      Total Days: <span>{totalDaysSelected}</span>
      <br />
      Cost Per Day: <span>x $1.00</span>
      <div className={classes.totalCost}>
        Total Cost: <span>${totalDaysSelected}.00</span>
      </div>
    </div>
  );
}

export default injectSheet(styles)(TotalCost);
