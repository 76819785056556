import React, { useState } from 'react';
import useSnackbar from '../../Snackbar/useSnackbar';
import injectSheet from 'react-jss';

// Components
import Modal, { ModalHeader, ModalContent, ModalActions } from '../../Modal';
import Button from '../../Button';
import DatesSelected from '../../DateRangePicker/DatesSelected';
import TotalCost from '../../IdentifierBacklogForm/TotalCost';
import KeyValue from '../../KeyValue';
import TestLocationCard from '../../TestLocationCard';

interface Props {
  toggleModal: () => void;
  form: {
    nameOnCard: string;
    email: string;
    selectedTestLocation?: any;
    startDate?: any;
    endDate?: any;
  };
  classes: any;
  open: boolean;
}

function ConfirmIdentifierLogPayment({ open, toggleModal, form, classes }) {
  const { addToSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleSend = () => {
    this.props.toggleModal();
  };

  return (
    // @ts-ignore
    <Modal open={open}>
      <ModalHeader>Purchase Details</ModalHeader>
      <ModalContent>
        <TestLocationCard testLocation={form.selectedTestLocation} />
        <KeyValue name="Email: " value={form.email} />
        <DatesSelected form={form} />
        <div className={classes.totalCostContainer}>
          <TotalCost form={form} />
        </div>
      </ModalContent>
      <ModalActions>
        <Button color="white" onClick={toggleModal} size="small">
          Close
        </Button>
        <Button color="moonBlue" onClick={handleSend} disabled={isLoading} size="small">
          Confirm Payment
        </Button>
      </ModalActions>
    </Modal>
  );
}

const styles = theme => {
  return {
    totalCostContainer: {
      marginTop: theme.spacing.unit * 2,
      display: 'flex',
    },
  };
};

// @ts-ignore
export default injectSheet(styles)(ConfirmIdentifierLogPayment);
