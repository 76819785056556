import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import injectSheet from 'react-jss';

// Components
import Logout from '../Logout';
import Link from 'next/link';
import Icon from '../Icon';

interface Props {
  classes: any;
  className?: string;
}

function ProfileDropdown({ classes, className }: Props) {
  const { authToken, email } = useSelector(state => state.auth);
  const auth = useSelector(state => state.auth);
  const displayEmail = useMemo(() => email && email.substring(0, email.indexOf('@')), [email]);
  const [show, setShow] = useState(false);

  return authToken ? (
    <div className={classes.wrapper}>
      <div className={classNames(classes.root, className)} onClick={() => setShow(!show)}>
        <span className={classes.profileText}>{displayEmail}</span>
        <Icon classes={{ root: classes.icon }}>arrow_drop_down</Icon>
      </div>
      <div
        className={classes.dropdown}
        style={show ? { top: 45, opacity: 1, pointerEvents: 'all' } : undefined}
      >
        <Link href="/settings">
          <div className={classNames(classes.row, className)}>Settings</div>
        </Link>
        <div className={classNames(classes.row, className)}>
          <Logout />
        </div>
      </div>
    </div>
  ) : null;
}

const styles = theme => {
  return {
    wrapper: {
      position: 'relative',
    },
    root: {
      position: 'relative',
      zIndex: 1,
      [theme.media.mobileNav]: {
        display: 'none',
      },
    },
    profileText: {
      maxWidth: 150,
      minWidth: 60,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'inline-block',
      textTransform: 'capitalize',
    },
    dropdown: {
      [theme.media.desktopNav]: {
        transition: 'all .4s',
        opacity: 0,
        position: 'absolute',
        zIndex: 0,
        top: -10,
        right: 0,
        minWidth: 100,
        borderTop: `1px solid ${theme.palette.common.gray}`,
        borderLeft: `1px solid ${theme.palette.common.gray}`,
        borderRight: `1px solid ${theme.palette.common.gray}`,
        pointerEvents: 'none',
        '& > *': {
          backgroundColor: theme.palette.common.white,
          marginLeft: `0px !important`,
          borderBottom: `1px solid ${theme.palette.common.gray}`,
        },
      },
    },
    icon: {
      marginTop: -8,
    },
  };
};

export default injectSheet(styles)(ProfileDropdown);
