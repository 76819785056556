import React from 'react';
import injectSheet from 'react-jss';

interface Props {
  classes: any;
  message?: string;
}

function ErrorMessage({ classes, message }: Props) {
  return message ? <div className={classes.root}>{message}</div> : null;
}

const styles = theme => {
  return {
    root: {
      marginTop: theme.spacing.unit / 2,
      fontSize: 12,
      color: theme.palette.error.text,
    },
  };
};

export default injectSheet(styles)(ErrorMessage);
