import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Style
import injectSheet from 'react-jss';
import classnames from 'classnames';
const styles = theme => {
  return {
    root: {
      fontSize: 24,
      '& a': {
        color: theme.palette.primary.main + ' !important',
      },
    },
    image: {
      width: 35,
      marginTop: 1,
      marginRight: 2,
    },
  };
};

class Logo extends React.PureComponent {
  render() {
    const { classes, className } = this.props;
    return (
      <div className={classnames(classes.root, className)}>
        <Link href="/">
          <a href="/">
            <img
              src="/static/images/testnotify-icon.png"
              alt="testnotify icon"
              className={classes.image}
            />
          </a>
        </Link>
      </div>
    );
  }
}

Logo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default injectSheet(styles)(Logo);
