// Widths
const widths = {
  mobileNav: 840,
  page: 1200,
  content: 700,
  section: 600,
  form: 520,
  card: 400,
};

const transitionSpeeds = {
  norm: 0.4,
  fast: 0.2,
  slow: 0.8,
};

const moonBlue = '#5e659c';
const midnight = '#2f2f2f';
// #aeb7f5 used on date-range-picker

export default {
  palette: {
    type: 'normal', // used for dark theme
    defaultText: '#444444',
    primary: {
      light: '#626dc1',
      main: moonBlue,
      dark: '#3b3f61',
      contrastText: '#444444',
      text: '#007bff', // hyperlink was #2a9dff
      disabled: '#eaedff',
      hoverOver: '#9fabff',
    },
    secondary: {
      light: '#444444',
      main: midnight,
      dark: '',
      contrastText: '',
    },
    error: {
      light: '#ffd5d5',
      text: '#ff3838',
    },

    common: {
      white: '#fff',
      gray: '#ebecf3',
      divider: '#888888',
    },

    // Reserved for actual theme objects
    warning: {},
    info: {},
    success: {},
    gray: {},
  },
  spacing: {
    unit: 8,
  },
  widths,
  heights: {
    navbar: 64,
  },
  media: {
    testLocation: '@media (max-width: 815px)',
    mobileNav: `@media (max-width: ${widths.mobileNav}px)`,
    desktopNav: `@media (min-width: ${widths.mobileNav}px)`,
    md: '@media (max-width: 991px)',
    sm: '@media (max-width: 630px)',
    xs: '@media (max-width: 480px)',
  },
  transition: {
    norm: `${transitionSpeeds.norm}s`,
    fast: `${transitionSpeeds.fast}s`,
    slow: `${transitionSpeeds.slow}s`,
    speeds: transitionSpeeds,
  },
};

// #467bca
